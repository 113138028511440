import React, { useState, useRef } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { updateQuote } from '../../actions/quoteActions'

import { TextField } from '@material-ui/core'

export const QuoteAccModal = ({ openState, accList, restOfQuote, linesRaw, quoteId }) => {
  const dispatch = useDispatch()

  //Global State

  //LOCAL state
  const [isOpen, setIsOpen] = useState(openState)

  //Handlers, etc-----
  const closeHandler = () => {
    console.log('close handler')
    setIsOpen(false)
  }
  const submitHandler = (e) => {
    e.preventDefault()
    handleCalculate()
    setIsOpen(false)
  }

  const [lines, setLines] = useState(accList)
  const latestLines = useRef(lines)
  function updateLines(newState) {
    latestLines.current = newState
    setLines(newState)
  }

  const handleLineRatio = (value, idx) => {
    const updatedLines = lines.map((line, sidx) => {
      if (idx !== sidx) return line
      return { ...line, ratio: value }
    })
    updateLines(updatedLines)
  }

  const handleLineTotal = (value, idx) => {
    const updatedLines = lines.map((line, sidx) => {
      if (idx !== sidx) return line
      let newRatio = value / line.quantity
      return { ...line, ratio: newRatio }
    })
    updateLines(updatedLines)
  }

  const handleCalculate = () => {
    let qtyLines = lines.map((line) => {
      let newTotal = line.quantity * line.ratio
      return { ...line, total: newTotal }
    })
    var result = qtyLines.reduce((acc, val) => {
      var o = acc
        .filter((obj) => {
          return obj.item === val.item
        })
        .pop() || { item: val.item, total: 0 }

      o.total += val.total
      acc.push(o)
      return acc
    }, [])
    //remove dupes
    var finalResult = result.filter((itm, i, a) => {
      return i === a.indexOf(itm)
    })
    //map to code and quantity
    finalResult = finalResult.map((item) => {
      return { code: item.item, quantity: item.total }
    })
    console.log(finalResult)
    console.log({ ...restOfQuote, lines: [...linesRaw, ...finalResult] })
    updateHandler(finalResult)
  }

  const updateHandler = (accItems) => {
    console.log('update')
    dispatch(updateQuote({ ...restOfQuote, lines: [...linesRaw, ...accItems] }, quoteId))
  }

  return (
    <Modal show={isOpen} onHide={closeHandler} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add Accessories</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={submitHandler}>
          {lines.map((line, index) => (
            <div key={index}>
              <TextField
                label="code"
                value={line.code}
                variant="standard"
                InputProps={{
                  readOnly: true,
                  className: 'tf-4',
                }}
                inputProps={{
                  size: 4,
                  tabIndex: '-1',
                  step: '0.01',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <span className="align-middle px-2 lead"> gets </span>
              <TextField
                label="qty"
                value={line.quantity}
                variant="standard"
                disabled
                InputProps={{
                  readOnly: true,
                  className: 'tf-3',
                }}
                inputProps={{
                  size: 4,
                  tabIndex: '-1',
                  step: '0.01',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <span className="align-middle px-2 lead"> x </span>
              <TextField
                label="per LF"
                value={line.ratio}
                onChange={(e) => handleLineRatio(e.target.value, index)}
                variant="standard"
                inputProps={{
                  size: 4,
                  step: '0.01',
                }}
              />
              <span className="align-middle px-2 lead"> = </span>
              <TextField
                label="Total"
                value={line.ratio * line.quantity}
                onChange={(e) => handleLineTotal(e.target.value, index)}
                variant="standard"
                inputProps={{
                  size: 4,
                }}
              />
              <span className="align-middle px-2 lead"> of </span>
              <TextField
                label="Accessory"
                value={line.item}
                disabled
                variant="standard"
                InputProps={{
                  readOnly: true,
                  className: 'tf-5',
                }}
                inputProps={{
                  size: 4,
                  tabIndex: '-1',
                  step: '0.01',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          ))}
        </Form>
        {/* {accList.map((acc) => (
          <div>
            {' '}
            {acc.code} gets {acc.ratio} * {acc.quantity} = {acc.ratio * acc.quantity} of {acc.item}{' '}
          </div>
        ))} */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeHandler}>
          Cancel
        </Button>
        <Button variant="primary" onClick={submitHandler}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
