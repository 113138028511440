import React from 'react'

export const JobAssignBadge = ({ cadCurrentName, cadCurrentId, userId }) => {
  let assigVariant = 'bg-light'
  let iconType = 'fas fa-users'
  if (cadCurrentId === userId) {
    assigVariant = 'bg-info'
    iconType = 'fas fa-user'
  } else if (cadCurrentId === 'Unassigned') {
    assigVariant = 'bg-danger'
    iconType = 'fas fa-briefcase'
  }
  return (
    <>
      <span className={`badge rounded-pill ${assigVariant}`}>
        {' '}
        <i className={iconType}></i> {cadCurrentName}
      </span>
    </>
  )
}
