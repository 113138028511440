import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Button, Table } from 'react-bootstrap'
import axios from 'axios'

export const JobCopyData = ({ job }) => {
  const [tableClass, setTableClass] = useState(true)
  const [driveError, setDriveError] = useState(null)
  const [driveData, setDriveData] = useState(null)
  // const [filePath, setFilePath] = useState('')
  let table = document.querySelector('#dataTableCopy')

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // useEffect(() => {
  //   if (job?.gdriveFolder) {
  //     async function fetchData() {
  //       try {
  //         const config = {
  //           headers: {
  //             'Content-Type': 'application/json',
  //             Authorization: `Bearer ${userInfo.token}`,
  //           },
  //         }
  //         const tokenResponse = await axios.get('/api/v1/admin/utils/get-gdrive-token', config)
  //         const tokenData = JSON.parse(tokenResponse.data)
  //         // console.log('Token.data:', tokenData)
  //         // console.log('API Key:', process.env.REACT_APP_GOOGLE_API_KEY)

  //         // Extract the access token from the response
  //         const accessToken = tokenData.access_token

  //         if (!accessToken) {
  //           throw new Error('No access token received from backend')
  //         }

  //         // Use the access token in the Authorization header
  //         // Also include your API key as a query parameter
  //         const gdriveRes = await axios.get(
  //           `https://www.googleapis.com/drive/v3/files/${job?.gdriveFolder}?key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
  //           {
  //             headers: {
  //               Authorization: `Bearer ${accessToken}`,
  //               'Content-Type': 'application/json',
  //             },
  //           }
  //         )

  //         console.log('Google Drive response:', gdriveRes.data)
  //         setDriveData(gdriveRes.data)
  //       } catch (error) {
  //         console.error('Google Drive API error:', error)
  //         setDriveError(error.response?.data?.error?.message || 'Failed to fetch Google Drive data')

  //         // Log more details about the error
  //         if (error.response) {
  //           console.error('Error status:', error.response.status)
  //           console.error('Error data:', error.response.data)

  //           // Check if the token is expired
  //           if (error.response.status === 401) {
  //             console.error('Token might be expired or invalid. Check the token from your backend.')
  //           }
  //         }
  //       }
  //     }
  //     fetchData()
  //   }
  // }, [])

  const selectNode = (node) => {
    let range = document.createRange()
    range.selectNodeContents(node)
    let select = window.getSelection()
    select.removeAllRanges()
    select.addRange(range)
  }
  const deselectNode = (node) => {
    let range = document.createRange()
    range.selectNodeContents(node)
    let select = window.getSelection()
    select.removeAllRanges()
  }

  const copyHandler = () => {
    setTableClass(!tableClass)
    selectNode(table)
    document.execCommand('copy')
    deselectNode(table)
  }

  return (
    <>
      <div className="py-2">
        Job Folder:
        <a href={`https://drive.google.com/drive/folders/${job.gdriveFolder}`} target="_blank" rel="noreferrer">
          Drive: {job.gdriveFolder}
        </a>
      </div>

      <Button variant="info" onClick={copyHandler}>
        <i class="fas fa-copy"></i> Copy/Toggle Data Row
      </Button>
      <div id="dataTableCopyDiv" className={tableClass ? 'display-offscreen' : 'table-responsive-md'}>
        <Table id="dataTableCopy">
          <tbody>
            <tr>
              <td className="p-1 mw-10">{job.owner?.inits}</td>
              <td className="p-1"></td>
              <td className="p-1"></td>
              <td className="p-1 mw-10">{job.num}</td>
              <td className="p-1 mw-10">{job.owner?.inits}</td>
              <td className="p-1"></td>
              <td className="p-1"></td>
              <td className="p-1 mw-10">{job.owner?.companyName}</td>
              <td className="p-1"></td>
              <td className="p-1"></td>
              <td className="p-1">{job.name}</td>
              <td className="p-1">
                {job.street} {job.city} {job.state}
              </td>
              <td className="p-1"></td>
              <td className="p-1"></td>
              <td className="p-1">{job.pan1Name}</td>
              <td className="p-1">{job.pan1Width}</td>
              <td className="p-1">{job.pan2Name}</td>
              <td className="p-1">{job.pan2Width}</td>
              <td className="p-1">{job.specialReq}</td>
              <td className="p-1"></td>
              <td className="p-1"></td>
              <td className="p-1"></td>
              <td className="p-1"></td>
              <td className="p-1"></td>
              <td className="p-1"></td>
              <td className="p-1">{job.owner?.inits}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  )
}
