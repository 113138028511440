import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AdminMrm } from '../../components/componentLib'
import { RBA_ADMIN } from '../../utils/rba'

export const AdminMrmLayout = ({ history }) => {
  //CHECK LOGIN
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  useEffect(() => {
    if (!userInfo) {
      history?.push('/login')
    } else if (RBA_ADMIN.includes(userInfo?.role)) {
      //do nothing
    } else {
      history.push('/')
    }
  }, [userInfo, history])

  // console.log(userInfo)

  return (
    <>
      <AdminMrm />{' '}
    </>
  )
}
