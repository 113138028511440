import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer, IndexLinkContainer } from 'react-router-bootstrap'
import { useHistory } from 'react-router-dom'
import { Container, Navbar, Nav, Dropdown } from 'react-bootstrap'
import { logout, getUserDetail } from '../actions/userActions'
import { RBA_ADMIN, RBA_CLIENT } from '../utils/rba'

export function Header() {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userDetail = useSelector((state) => state.userDetail)
  const { error, user } = userDetail

  const dispatch = useDispatch()

  useEffect(() => {
    if (userInfo) {
      dispatch(getUserDetail(userInfo?._id))
    }
  }, [userInfo, dispatch])

  const history = useHistory()
  const logoutHandler = () => {
    dispatch(logout())
    history.push('/login')
  }

  const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches
  const theme = defaultDark ? 'dark' : 'light'

  return (
    <div data-theme={theme}>
      <header>
        <Navbar bg="light" variant="light" collapseOnSelect expand="lg" style={{ fontSize: '1.1rem' }}>
          <Container fluid>
            <LinkContainer to="/">
              <Navbar.Brand href="/home">
                <img src="/mrq-logo.png" width="135" height="55" className="d-inline-block align-top" alt="MRQ Logo" />
              </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                {user && userInfo?.name && !error ? (
                  <>
                    <IndexLinkContainer to="/">
                      <Nav.Link>
                        <i className="fad fa-home fa-theme" /> Dashboard <strong>|</strong>{' '}
                        <i className="fad fa-plus-circle fa-theme" /> New
                      </Nav.Link>
                    </IndexLinkContainer>
                    <IndexLinkContainer to="/jobs">
                      <Nav.Link>
                        <i className="fad fa-list-ul fa-theme" /> Estimates
                      </Nav.Link>
                    </IndexLinkContainer>
                    {/* {((userInfo.hasQuoter && RBA_CLIENT.includes(userInfo?.role)) ||
                      RBA_ADMIN.includes(userInfo?.role)) && (
                      <>
                        <IndexLinkContainer to='/quotes'>
                          <Nav.Link>
                            <i className='fad fa-clipboard-list-check fa-theme' /> Quotes
                          </Nav.Link>
                        </IndexLinkContainer>
                      </>
                    )} */}
                    <Dropdown>
                      <Dropdown.Toggle as={Nav.Link} id="basic-nav-dropdown">
                        <i className="fad fa-tools fa-theme" /> Tools
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {((userInfo.hasQuoter && RBA_CLIENT.includes(userInfo?.role)) ||
                          RBA_ADMIN.includes(userInfo?.role)) && (
                          <>
                            <Dropdown.Item href="/customers">
                              <i className="fad fa-address-book fa-theme" /> Customers
                            </Dropdown.Item>
                            <Dropdown.Item href="/items">
                              <i className="fad fa-box-open fa-theme" /> Catalog
                            </Dropdown.Item>
                            <Dropdown.Divider />
                          </>
                        )}
                        <Dropdown.Item href={`/clients/${userInfo.client}`}>
                          <i className="fad fa-cogs fa-theme" /> Customize
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    {RBA_ADMIN.indexOf(userInfo?.role) >= 0 && !error ? (
                      <>
                        <Dropdown>
                          <Dropdown.Toggle as={Nav.Link} id="basic-nav-dropdown">
                            <i className="fad fa-users-cog fa-theme" /> Admin
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item href="/users">
                              <i className="fad fa-users fa-theme" /> Users
                            </Dropdown.Item>
                            <Dropdown.Item href="/clients">
                              <i className="fad fa-sitemap fa-theme" /> Clients
                            </Dropdown.Item>
                            <Dropdown.Item href="/admin/data">
                              <i className="fad fa-chart-line fa-theme" /> Data
                            </Dropdown.Item>
                            <Dropdown.Item href="/admin/mrm">
                              <i className="fa-duotone fa-solid fa-users-gear" /> MRM
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item href="/admin/billing">
                              <i className="fad fa-funnel-dollar fa-theme" /> Billing
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </>
                    ) : (
                      ''
                    )}
                    {/* User profile and logout */}
                    <Dropdown id="username">
                      <Dropdown.Toggle as={Nav.Link} id="dropdown-user">
                        <i className="fad fa-user-edit fa-theme" />
                        <strong>|</strong>
                        <i className="fad fa-sign-out-alt fa-theme" /> {userInfo.name}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <LinkContainer to="/profile">
                          <Dropdown.Item>
                            <i className="fad fa-user-edit fa-theme" /> Edit Profile
                          </Dropdown.Item>
                        </LinkContainer>
                        <Dropdown.Item onClick={logoutHandler}>
                          <i className="fad fa-sign-out-alt fa-theme" /> Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Nav.Link href="mailto:quotes@ez-timate.com" target="_blank">
                      <i className="fad fa-envelope fa-theme" /> Email Us
                    </Nav.Link>
                  </>
                ) : (
                  <>
                    <LinkContainer to="/login">
                      <Nav.Link>
                        <i className="fad fa-sign-in-alt fa-theme" /> Login
                      </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/register">
                      <Nav.Link>
                        <i className="fad fa-user-plus fa-theme" /> Register
                      </Nav.Link>
                    </LinkContainer>
                    <Nav.Link href="mailto:quotes@ez-timate.com" target="_blank">
                      <i className="fad fa-envelope fa-theme " />
                      Email Us
                    </Nav.Link>
                  </>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </div>
  )
}
