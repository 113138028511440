import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Button, Card, Form } from 'react-bootstrap'
import { RBA_ADMIN } from '../../utils/rba'
// import { Link } from 'react-router-dom'

import { getClientDetailById, updateClient } from '../../actions/clientActions'
import { Message, Loader } from '../../components/componentLib'

export const AdminMrm = ({ history, match }) => {
  const mrmId = '66e83387bd414ba9a17d709b'

  //LOCAL SCOPE
  const [updateSuccessLocal, setUpdateSuccessLocal] = useState(false)
  const [localMrmClients, setLocalMrmClients] = useState([])

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const clientDetail = useSelector((state) => state.clientDetail)
  const { loading, error, client, loadingUpdate, errorUpdate, successUpdate } = clientDetail

  useEffect(() => {
    if (client) {
      setLocalMrmClients(client.mrmClients)
    }
  }, [client])

  //CHECK LOGIN
  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else if (RBA_ADMIN.includes(userInfo?.role)) {
      dispatch(getClientDetailById(mrmId))
    } else {
      history.push('/')
    }
  }, [history, dispatch, match, userInfo])

  useEffect(() => {
    successUpdate === true && setUpdateSuccessLocal(true)
    setTimeout(() => setUpdateSuccessLocal(false), 5000)
  }, [successUpdate])
  console.log('clientDetail', clientDetail)

  const handleFormUpdate = ({ mrmClientId, field, value }) => {
    console.log('handleFormUpdate', mrmClientId, field, value)
    let tempArray = JSON.parse(JSON.stringify(localMrmClients))
    let lineIndex = tempArray.findIndex((line) => line.mrmClientId === mrmClientId)
    let line = tempArray[lineIndex]
    line[field] = value
    tempArray[lineIndex] = line
    setLocalMrmClients(tempArray)
  }

  const handleUpdate = (e) => {
    e.preventDefault()
    console.log('update')
    const updatedClient = {
      _id: mrmId,
      mrmClients: localMrmClients,
    }
    console.log(updatedClient)
    dispatch(updateClient(updatedClient))
  }

  return (
    <Row>
      <Col md={4}>
        {(error || errorUpdate) && <Message variant="danger">{error || errorUpdate}</Message>}
        {updateSuccessLocal && <Message variant="success">Data Updated!</Message>}
        {(loading || loadingUpdate) && <Loader />}
        {localMrmClients && !error && (
          <div>
            {localMrmClients.map((line) => (
              <Card className="my-3 p-3 rounded" key={line._id}>
                <Card.Body>
                  <span>
                    Name from MRM: <strong>{line.mrmCompanyName} </strong>
                    <br />
                  </span>
                  <Form.Group controlId="mrmClientName">
                    <Form.Label>Internal Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter internal name"
                      value={line.mrqName}
                      onChange={(e) =>
                        handleFormUpdate({ mrmClientId: line.mrmClientId, field: 'mrqName', value: e.target.value })
                      }
                    />
                  </Form.Group>
                  <Form.Group controlId="mrmClientName">
                    <Form.Label>Internal Notes:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Notes..."
                      value={line.mrqNotes}
                      onChange={(e) =>
                        handleFormUpdate({ mrmClientId: line.mrmClientId, field: 'mrqNotes', value: e.target.value })
                      }
                    />
                  </Form.Group>
                  <Button variant="primary" type="submit" onClick={handleUpdate}>
                    Submit
                  </Button>
                </Card.Body>
              </Card>
            ))}
          </div>
        )}
      </Col>
      <Col md={6}>
        <div>col2</div>
      </Col>
    </Row>
  )
}
