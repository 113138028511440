import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateQuote, getQuoteById } from '../../actions/quoteActions'
import { Loader, Message, QuoteDetailCutlistPack } from '../componentLib'

import { TextField, IconButton, Grid } from '@material-ui/core'
import { Card } from 'react-bootstrap'

import DeleteIcon from '@material-ui/icons/Delete'

export const QuoteDetailCutlist = ({ userInfo, quoteId }) => {
  const dispatch = useDispatch()

  const emptyLine = {
    quantity: '',
    feet: '',
    inch: '',
    linearFeet: '',
    weight: '',
  }
  const emptyLines = [emptyLine, emptyLine]

  useEffect(() => {
    if (userInfo) {
      dispatch(getQuoteById(quoteId))
    }
  }, [dispatch, quoteId, userInfo])

  const quoteDetail = useSelector((state) => state.quoteDetail)
  const { loading, error, quote } = quoteDetail

  //LOCAL STATE

  const [panelsRaw, setPanelsRaw] = useState('')
  const [cutlist, setCutlist] = useState(emptyLines)
  const [linearFeet, setLinearFeet] = useState('')
  const [poundPerLf, setPoundPerLf] = useState('')
  const [maxWeight, setMaxWeight] = useState(500)

  //const [orderedList, setOrderedList] = useState([])
  //const [packingList, setPackingList] = useState([])

  useEffect(() => {
    if (quote?._id) {
      setCutlist(quote.cutlist?.length > 0 ? quote.cutlist : emptyLines)
      setPanelsRaw(quote.panelsRaw)
    }
  }, [quote, emptyLines])

  useEffect(() => {
    if (cutlist.length > 0) {
      updateLinearFeet(cutlist)
    }
  }, [cutlist, updateLinearFeet])

  useEffect(() => {
    if (quote.cutlist?.length > 0) {
      setCutlist(quote.cutlist)
    }
  }, [quote.cutlist?.length, quote.cutlist])

  const updateTopHandler = () => {
    console.log('update')
    if (quote._id) {
      dispatch(
        updateQuote(
          {
            cutlist,
            linearFeet,
            poundPerLf,
            maxWeight,
          },
          quote._id
        )
      )
    }
  }

  const correctFtIn = (length) => {
    return [Math.floor(length / 12), length % 12]
  }

  const updateLinearFeet = (linesInput) => {
    try {
      console.log(linesInput)
      const linesTotal = linesInput.reduce((acc, line) => acc + (Number(line.linearFeet) || Number(0)))
      console.log(linesTotal)
      setLinearFeet(linesTotal)
    } catch (err) {}
  }

  //Handlers for cutlist LINES-----
  const handleLineQty = (value, idx) => {
    console.log(`qty: ${value}`)
    const updatedLines = cutlist.map((line, sidx) => {
      if (idx !== sidx) {
        return line
      } else {
        return {
          ...line,
          quantity: value ? value * 1 : '',
          weight: (line.length / 12) * poundPerLf,
        }
      }
    })
    setCutlist(updatedLines)
    updateLinearFeet(updatedLines)
  }

  const handleLineFeet = (value, idx) => {
    console.log(`Feet: ${value}`)
    const updatedLines = cutlist.map((line, sidx) => {
      if (idx !== sidx) {
        return line
      } else {
        const linearFeet = (value * 12 + line.inch * 1) / 12
        return {
          ...line,
          feet: value ? value * 1 : '',
          linearFeet: linearFeet,
          weight: linearFeet * poundPerLf,
        }
      }
    })
    setCutlist(updatedLines)
    updateLinearFeet(updatedLines)
  }

  const handleLineInch = (value, idx) => {
    console.log(`Inch: ${value}`)
    const updatedLines = cutlist.map((line, sidx) => {
      if (idx !== sidx) {
        return line
      } else {
        let linearFeet = (line.feet * 12 + value * 1) / 12
        let correctedLf = correctFtIn(linearFeet * 12) //correctFtIn takes inches
        console.log(correctedLf)
        return {
          ...line,
          feet: correctedLf[0] * 1,
          inch: correctedLf[1] ? correctedLf[1] * 1 : '',
          linearFeet: linearFeet,
          weight: linearFeet * poundPerLf,
        }
      }
    })
    setCutlist(updatedLines)
    updateLinearFeet(updatedLines)
  }

  const handleNewLine = () => {
    console.log('new line')
    setCutlist(cutlist.concat(emptyLine))
  }

  const qtyInput = useRef(null)
  useEffect(() => {
    if (cutlist.length !== quote.cutlist?.length)
      setTimeout(() => {
        qtyInput.current && qtyInput.current.focus()
        console.log(qtyInput.current)
      }, 10)
  }, [cutlist.length])

  const doNothing = () => {
    console.log('doing nuthin')
  }

  const handleDeleteLine = (index) => {
    console.log('deleting ' + index)
    const updatedCutlist = cutlist.filter((line, sidx) => index !== sidx)
    setCutlist(updatedCutlist)
    updateLinearFeet(updatedCutlist)
  }

  //End handlers for order form LINEs-----

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : quote ? (
        <>
          <Card>
            <div>
              <button type="button" className="btn btn-info inline" onClick={updateTopHandler}>
                <i className="far fa-save"></i> Save
              </button>
            </div>

            <Grid container spacing={2}>
              <Grid item lg={6}>
                <form>
                  {/* Begin order form */}
                  <div id="order-container" className="pt-3">
                    <TextField
                      label="Lb/LF"
                      type="number"
                      value={poundPerLf}
                      onChange={(e) => setPoundPerLf(e.target.value)}
                      variant="standard"
                      InputProps={{
                        className: 'tf-5',
                      }}
                    />
                    <TextField
                      label="Max Wt."
                      type="number"
                      value={maxWeight}
                      onChange={(e) => setMaxWeight(e.target.value)}
                      variant="standard"
                      InputProps={{
                        className: 'tf-5',
                      }}
                    />
                    <TextField
                      label="LF"
                      type="number"
                      value={linearFeet}
                      disabled
                      variant="standard"
                      InputProps={{
                        readOnly: true,
                        className: 'tf-7',
                      }}
                      inputProps={{
                        tabIndex: '-1',
                        step: '0.01',
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    {/* End top header, begin lines */}

                    {cutlist.map((line, index) => (
                      <div key={index} id="cutlist-div">
                        <TextField
                          label="qty"
                          type="number"
                          value={line.quantity}
                          onChange={(e) => handleLineQty(e.target.value, index)}
                          variant="standard"
                          InputProps={{
                            className: 'tf-5 cutlist-qty',
                          }}
                          inputRef={qtyInput}
                        />
                        <TextField
                          label="ft"
                          type="number"
                          value={line.feet}
                          onChange={(e) => handleLineFeet(e.target.value, index)}
                          variant="standard"
                          InputProps={{
                            className: 'tf-5',
                          }}
                        />
                        <TextField
                          label="in"
                          type="number"
                          value={line.inch}
                          onChange={(e) => handleLineInch(e.target.value, index)}
                          onBlur={index === cutlist?.length - 1 ? handleNewLine : doNothing}
                          variant="standard"
                          InputProps={{
                            className: 'tf-5',
                          }}
                        />
                        <TextField
                          label="Length(in)"
                          type="number"
                          value={typeof line.linearFeet == 'number' ? line.linearFeet * 12 : line.linearFeet * 12}
                          disabled
                          variant="standard"
                          InputProps={{
                            readOnly: true,
                            className: 'tf-7',
                          }}
                          inputProps={{
                            tabIndex: '-1',
                            step: '0.01',
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <IconButton aria-label="delete" onClick={() => handleDeleteLine(index)} tabIndex="-1">
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    ))}
                    {/* End of map/forEach */}
                  </div>
                </form>
              </Grid>

              <Grid item lg={6}>
                {cutlist && <QuoteDetailCutlistPack cutlist={cutlist} maxWeight={maxWeight} poundPerLf={poundPerLf} />}
              </Grid>
            </Grid>
            <div>
              <button type="button" className="btn btn-info inline mt-3" onClick={updateTopHandler}>
                <i className="far fa-save"></i> Save
              </button>
            </div>
          </Card>

          {/* Dialogs to create customer and SKU */}
        </>
      ) : (
        ''
      )}
    </>
  )
}
